<template>

	<div>
		<section class="header-bg" style="min-height: 255px;">
			<div class="container-1200" style="padding-top: 40px;">

				<div style="width:76%;margin:0 auto;margin-top:150px;height: 400px;">
					<h2 style="text-align: center;color:white">HELP & SUPPORT</h2>
					<div class="header_content">

						<a href="" class="header_content1">
							<div>
								<img :src="require('@/assets/images/phone.png')" alt="">
								<h3>Contact us</h3>
								<p>Get in touch with a representative</p>
							</div>
						</a>


						<a href="" class='header_content1'>
							<div>
								<img :src="require('@/assets/images/personalInformation.png')" alt=""
									class="header_content11">
								<h3>Press resources</h3>
								<p>News,company info,and media resources</p>
							</div>
						</a>


						<a href="" class='header_content1'>
							<div>
								<img :src="require('@/assets/images/support.png')" alt="">
								<h3>Help & support</h3>
								<p>Get answers to all of your questions</p>
							</div>
						</a>


					</div>
				</div>
			</div>
		</section>
		<section class="container-1200 layui-fluid" style="min-height: 555px;">
			<div class="layui-row">
				<div class="layui-col-md6" style="margin-top: 80px;">
					<h1 style="font-size: 28px;"><strong>CONTACT INFO</strong></h1>
					<p style="line-height: 22px;font-size: 16px;margin-top: 10px;">
						We need to meet the requirements of our customers to the maximum extent possible.
					</p>
					<ul style="margin-top: 40px;">
						<li>
							<div class="layui-row layui-col-space20">
								<div class="layui-col-md1">
									<i class="layui-icon layui-icon-location"></i>
								</div>
								<div class="layui-col-md11">
									<p><strong>Address</strong></p>
									<p>B-7-28 Horizon Suites Blok B, Kota Warisan 43900 Sepang, Selangor, Malaysia</p>
								</div>
							</div>
						</li>
						<li>
							<div class="layui-row layui-col-space20">
								<div class="layui-col-md1">
									<i class="layui-icon layui-icon-email"></i>
								</div>
								<div class="layui-col-md11">
									<p><strong>Email</strong></p>
									<p><a style="color: black;cursor: pointer;text-align: inherit;justify-content: inherit;align-items: inherit;" href="mailto:contact@contrimetric.com">contact@contrimetric.com</a></p>
								</div>
							</div>
						</li>
					</ul>
					<div class="layui-fluid" style="margin-top: 40px;">
						<div style="display: flex;">
							<div style="margin-right: 20px">
								<img src="../assets/images/facebook.png" alt="" class="float-hand">
							</div>
							<div style="margin-right: 20px">
								<img src="../assets/images/twitter.png" alt="" class="float-hand">
							</div>
							<div style="margin-right: 20px">
								<img src="../assets/images/linkedin.png" alt="" class="float-hand">
							</div>
						</div>
					</div>
				</div>
				<div class="layui-col-md6" style="margin-top: 80px;">
					<img src="../assets/images/contact.jpg" style="width: 100%;" />
				</div>
			</div>
		</section>
		<!-- <section class="container-1200" style="margin-top: 40px;">
		        <div style="height: 20px;">
		            <h1 style="font-size: 28px;"><strong>SEND MESSAGE</strong></h1>
		        </div>
		        <form class="layui-form" id="myForm">
		            <div class="layui-form-item">
		                <div class="layui-inline" style="width: 384px;margin-top: 40px;">
		                    <div class="layui-input-inline" style="width: 384px">
		                        <input type="tel" name="nickname" autocomplete="off" class="layui-input" placeholder="Name">
		                    </div>
		                </div>
		                <div class="layui-inline" style="width: 384px;margin-top: 40px;">
		                    <div class="layui-input-inline" style="width: 384px">
		                        <input type="text" name="email" id="email" class="layui-input" autocomplete="off" placeholder="Email">
		                    </div>
		                </div>
		                <div class="layui-inline" style="width: 384px;margin-top: 40px;">
		                    <div class="layui-input-inline" style="width: 384px">
		                        <input type="text" name="phone"  class="layui-input" autocomplete="off"
		                               placeholder="phone">
		                    </div>
		                </div>
		            </div>
		            <div class="layui-form-item layui-form-text">
		                <div class="layui-input-block" style="margin-left: 0;max-width: 1182px;">
		                    <textarea placeholder="Question" name="content" class="layui-textarea"></textarea>
		                </div>
		            </div>
		            <button type="button" class="layui-btn" id="question">Send question</button>
		        </form>
		    </section> -->
	</div>


</template>

<script>
	export default {
		name: "contact"
	}
</script>

<style scoped>
	.header_content {
		margin-top: 30px;
		line-height: 26px;
		display: flex;
		justify-content: space-between;
	}



	.header_content1 {
		flex: 1;
		margin-right: 30px;
		border-radius: 20px;
		height: 300px;
		display: flex;
		background-color: #074463;
		justify-content: center;
		align-items: center;
	}

	a {
		display: flex;
		flex-direction: column;
		text-decoration: none;
		text-align: center;
		justify-content: center;
		align-items: center;
		color: white;
	}

	.header_content1 img {
		width: 60px;
		height: 60px;
		margin-bottom: 15px;
	}


	.header_content1:hover {
		background-color: #fff;
		color: black;
		box-shadow: 0 0 100px 0 rgba(0, 0, 0, .3)
	}

	.header_content1:hover img {
		border-radius: 50px;
		background-color: #1F79A8;
		/* ���������ͣʱͼƬ�ı�����ɫΪ��ɫ */
	}
</style>